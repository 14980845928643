import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {fetchWrapper} from '../../helpers';
import {API_GET_COMPONENT_THRESHOLD} from "../../constants/network";


// create slice
const name = 'tafReading';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const componentSlice = createSlice({name, initialState, extraReducers});

// exports

export const componentActions = {...componentSlice.actions, ...extraActions};

// implementation

function createInitialState() {
    return {}
}

function createExtraActions() {
    return {
        getComponentThreshold: getComponentThreshold(),
    };

    function getComponentThreshold() {
        return createAsyncThunk(
            `/componentThreshold/get`,
            async ({
                       projectName,
                       componentId,
                   }) => await fetchWrapper.get(`${API_GET_COMPONENT_THRESHOLD}/${projectName}/${componentId}/thresholds`, null)
        );
    }
}

function createExtraReducers() {
    return {
        ...getComponentThreshold(),
    };

    function getComponentThreshold() {
        let {pending, fulfilled, rejected} = extraActions.getComponentThreshold;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: (state, action) => {
                return action;
            }
        };
    }
}

export default componentSlice;