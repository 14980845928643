import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {measurementActions} from "../../store";
import { Chart } from "react-google-charts";

export default function TrendPlot({projectName, nodeId}) {
    const dispatch = useDispatch();
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                projectName && dispatch(measurementActions.getTrend({
                    projectName: projectName,
                    componentId: nodeId.substring(2)
                }))
                    .then((state) => {
                        setGraphData(state.payload);
                    });
            } else {
                setGraphData([]);
            }
        }
    }, [dispatch, nodeId, projectName]);

    if (!graphData || graphData.length === 0) {
        return;
    }
    const options = {
        chartArea: {width: "80%", left: "150"},
        curveType: "",
        hAxis: {
            title: "Date",
            textStyle: {
                fontSize : 10
            },
            slantedText: true,
            slantedTextAngle: 45,
        },
        vAxis: {
            title: "G's",
            ticks: [
                {v:0.7, f:'Overall Y-Alert 0.7'},
                {v:1.2, f:'Overall R-Alert 1.2'},
                {v:2.0, f:'Peak Y-Alert 2.0'},
                {v:4.0, f:'Peak R-Alert 4.0'}
            ]
        },
        legend: { position: "top" },
    };

    return (
        <div>
            <Box display="flex" height="70vh" m="-2px 0 0 0">
                <Chart
                    chartType="LineChart"
                    data={graphData}
                    options={options}
                    height="90%"
                    style={{flex: 2}}
                />
            </Box>
        </div>
    );
}