// noinspection JSUnresolvedReference

import {Box, Button, Chip} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Moment from "moment/moment";
import { nodeActions } from "../../store";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import * as React from "react";

const statusColor = (status) => {
  switch (status) {
    case "GOOD":
      return "success";
    case "ALERT":
      return "warning";
    case "DANGER":
      return "error";
    default:
      return "default";
  }
};


export default function ModelStatusGrid({
  projectName,
  nodeId,
  setNodeId,
  nodeType,
  setSelected,
  expanded,
  setExpanded,
}) {
  const dispatch = useDispatch();

  // DataGrid {{
  const columns = [
    {field: "id", disableColumnSorting: true, filterable: false, hide: true},
    {field: "parentId", hide: true},
    {
      field: "name",
      align: "left",
      editable: false,
      headerAlign: "center",
      headerName: "name",
      width: 160,
    },
    {
      field: "lastReading",
      align: "left",
      editable: false,
      filterable: false,
      headerAlign: "center",
      headerName: "Last Reading TimeStamp",
      valueFormatter: (params) =>
          params.value ? new Moment(params.value).format("YYYY/MM/DD HH:mm:ss") : "",
      width: 160,
    },
    {
      field: "nodeStatus",
      align: "center",
      editable: false,
      filterable: false,
      headerAlign: "center",
      headerName: "Status",
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return (
            <Chip
                color={statusColor(params.row.nodeStatus)}
                label={params.row.nodeStatus}
                sx={{borderWidth: "2px", fontWeight: "bold"}}
            />
        );
      },
    },
    {
      field: "location",
      align: "center",
      editable: false,
      filterable: false,
      headerAlign: "center",
      headerName: "Location",
      width: 190,
    },
    {
      field: "button",
      filterable: false,
      headerName: "",
      width: 190,
      renderCell: (params) => {
        return (
            <span>
            <Button
                onClick={() => onClickSelect(params.row)}
                variant="contained"
                style={{
                  alignItems: "center",
                  backgroundColor: "#0077CC",
                  fontSize: "12px",
                  fontWeight: "bold",
                  margin: "4px",
                }}
            >
              Select
            </Button>
            <Button
                onClick={() => showMap(params.row.latitude, params.row.longitude)}
                variant="contained"
                style={{
                  alignItems: "center",
                  backgroundColor: "#0077CC",
                  fontSize: "12px",
                  fontWeight: "bold",
                  margin: "4px",
                }}
            >
              Map
            </Button>
          </span>
        );
      },
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("lastReading,desc");
  const [filterByColumn, setFilterByColumn] = useState("");
  const [filterByValue, setFilterByValue] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [rows, setRows] = useState([]);

  useEffect(() => {
      let pageable = {size: pageSize, page, sort};

      if (filterByColumn && filterByValue) {
          pageable[filterByColumn] = filterByValue;
      }

      setIsLoading(true);
      const getNodeId = (nodeId) => (nodeId ? nodeId.substring(2) : nodeId);
      dispatch(
          nodeActions.getNodeStatusList({
              projectName,
              nodeId: getNodeId(nodeId),
              nodeType,
              pageable,
          })
      ).then((state) => {
          if (state.payload) {
              setRows(state.payload.content);
              setRowCount(state.payload.totalElements);
          }
          setIsLoading(false);
      });
  }, [
    filterByColumn,
    filterByValue,
    projectName,
    nodeId,
    nodeType,
    pageSize,
    page,
    sort,
    dispatch,
  ]);

  const handleSortModelChange = (sortModel) => {
    if (sortModel && sortModel.length > 0) {
      const orderBy = sortModel[0].field;
      const order = sortModel[0].sort;
      setSort(orderBy + "," + order);
    }
  };

  const handleFilterModelChange = (filterModel) => {
    if (filterModel && filterModel.items && filterModel.items.length > 0) {
      setFilterByColumn(filterModel.items[0].columnField);
      setFilterByValue(filterModel.items[0].value);
    } else {
      setFilterByColumn("");
      setFilterByValue("");
    }
  };
  // }} DataGrid

  const onClickSelect = (row) => {
    console.log("OnClickSelect", row);
    const newSelected = (row.nodeType === "SUB_SYSTEM" ? "U" : row.nodeType.substring(0, 1)) + "-" + row.id;
    const newExpanded = [
      ...new Set([].concat([newSelected], [nodeId], expanded)),
    ];

    setNodeId(newSelected);
    setSelected(newSelected);
    setExpanded(newExpanded);
  };

  const showMap = (latitude, longitude) => {
    let url = "https://maps.google.com/?q=" + latitude + "," + longitude;
    window.open(url);
  };

  return (
      <Box sx={{width: "100%"}}>
        <Box height="70vh">
          <DataGrid
              columns={columns}
              filterMode="server"
              loading={isLoading}
              onFilterModelChange={handleFilterModelChange}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={handleSortModelChange}
              pageSize={pageSize}
              pagination
              paginationMode="server"
              rowCount={rowCount}
              rows={rows}
              rowsPerPageOptions={[10, 25, 50, 100]}
              sortingMode="server"
              sx={{
                "& .normal": {},
                "& .warning": {
                  backgroundColor: "#cccc0022",
                },
                "& .danger": {
                  backgroundColor: "#ff000022",
                },
              }}
          />
        </Box>
      </Box>
  );
}
