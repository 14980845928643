import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {fetchWrapper, objToQueryString} from '../../helpers';
import {API_FAT_READING_PAGE} from "../../constants/network";


// create slice
const name = 'tafReading';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const fatReadingSlice = createSlice({name, initialState, extraReducers});

// exports

export const fatReadingActions = {...fatReadingSlice.actions, ...extraActions};

// implementation

function createInitialState() {
    return {}
}

function createExtraActions() {
    return {
        getFatReadingPage: getFatReadingPage(),
    };

    function getFatReadingPage() {
        return createAsyncThunk(
            `/fatReading/get`,
            async ({
                       projectName,
                       componentId,
                       pageable,
                   }) => await fetchWrapper.get(`${API_FAT_READING_PAGE}/${projectName}/${componentId}?${objToQueryString(pageable)}`, null)
        );
    }
}

function createExtraReducers() {
    return {
        ...getFatReadingPage(),
    };

    function getFatReadingPage() {
        let {pending, fulfilled, rejected} = extraActions.getFatReadingPage;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: (state, action) => {
                return action;
            }
        };
    }
}

export default fatReadingSlice;