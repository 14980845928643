import * as React from "react";
import ModelStatusGrid from "./ModelStatusGrid";

export default function ModelDetailWindow({
  projectName,
  nodeId,
  setNodeId,
  nodeType,
  setSelected,
  expanded,
  setExpanded,
}) {
  return (
    <ModelStatusGrid
      projectName={projectName}
      nodeId={nodeId}
      setNodeId={setNodeId}
      nodeType={nodeType}
      setSelected={setSelected}
      expanded={expanded}
      setExpanded={setExpanded}
    />
  );
}
