import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import CacHealthComponent from "../cacHealth/cacHealthComponent";
import {useEffect} from "react";
import {nodeActions} from "../../store";
import {useDispatch} from "react-redux";
import TabPanel, {a11yProps} from "../global/TabPanel";

export default function CACHealthTest({
                                          isDashboard,
                                          nodeId,
                                          nodeType,
                                          projectName,
                                          graphType,
                                          setGraphType,
                                          setCollectionTime,
                                          setTabId,
                                          tabId = 0
                                          }) {

    const handleChange = (event, newValue) => {
        setTabId(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    value={tabId}
                    onChange={handleChange}
                    aria-label="Collection Time"
                    textColor="primary"
                    indicatorColor="secondary"
                    variant="scrollable">
                    orientation="vertical"
                    <Tab
                        icon={<MonitorHeartIcon/>}
                        label="CAC Health Test" {...a11yProps(0)} />
                    </Tabs>
            </Box>
            <TabPanel value={tabId} index={0}>
                <CacHealthComponent
                    isDashboard={isDashboard}
                    nodeId={nodeId}
                    projectName={projectName}
                    setCollectionTime={setCollectionTime}
                    graphType={graphType}
                    setGraphType={setGraphType}
                />
            </TabPanel>
        </Box>
    );
}
