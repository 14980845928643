import * as React from 'react';
import {useEffect, useState} from "react";
import ThermalTapPanel from "./ThermalTabPanel";
import ThermalImage from "./ThermalPlot";
import ThermalStats from "./ThermalStats";

export default function ThermalComponent({ projectName, nodeId }) {
    const [tabId, setTabId] = React.useState(0);
    const [gridData, setGridData] = useState([]);
    const [thermalImageId, setThermalImageId] = useState(null);
    const [thermalImageStatsId, setThermalImageStatsId] = useState(null);
    const [collectionTime, setCollectionTime] = useState(null);

    useEffect(() => {
    }, [thermalImageId, thermalImageStatsId, collectionTime]);

    if ((!thermalImageId)&&(!thermalImageStatsId)) {
        return (
            <ThermalTapPanel
                gridData={gridData}
                nodeId={nodeId}
                projectName={projectName}
                setGridData={setGridData}
                tabId={tabId}
                setTabId={setTabId}
                setThermalImageId={setThermalImageId}
                setThermalImageStatsId={setThermalImageStatsId}
                setCollectionTime={setCollectionTime}
            />
        );
    } else if (!thermalImageId) {
        return (
            <ThermalStats
                nodeId={nodeId}
                projectName={projectName}
                thermalImageStatsId={thermalImageStatsId}
                setThermalImageStatsId={setThermalImageStatsId}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
            />
        );

    } else if (!thermalImageStatsId) {
        return (
            <ThermalImage
                nodeId={nodeId}
                projectName={projectName}
                thermalImageId={thermalImageId}
                setThermalImageId={setThermalImageId}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
            />
        );
    }

}
