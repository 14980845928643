import {Box, IconButton, useTheme} from "@mui/material";
import {useContext} from "react";
import {ColorModeContext, tokens} from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import {Refresh} from "@mui/icons-material";
import {fetchWrapper} from "../../helpers";
import LogoutIcon from '@mui/icons-material/Logout';
import {API_GET_MEASUREMENT_LIST} from "../../constants/network";
import {useNavigate} from "react-router-dom";

export type TopBarProps = {
  children: ReactNode;
};

const Topbar =  ({ children }: TopBarProps) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();

    const handleClickRefresh = () => {
        fetchWrapper.post(`${API_GET_MEASUREMENT_LIST}`, null).then(() => {
            console.log("handleClickRefresh() done");
        });
    };

    const handleClickLogout = () => {
        localStorage.removeItem('authorization');
        navigate("/");
        window.location.reload();
    };

    return (
        <Box display="flex" height="10vh"
             justifyContent="space-between"
             x={{ mb: 1, ml: 1, mr: 1, mt: 1}}
             p={2}
        >
            <Box
                display="flex"
                borderRadius="2px"
                width="1200px" >
                { children }
            </Box>

            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon/>
                    ) : (
                        <LightModeOutlinedIcon/>
                    )}
                </IconButton>
                <IconButton onClick={handleClickRefresh}>
                    <Refresh/>
                </IconButton>
                <IconButton onClick={handleClickLogout}>
                    <LogoutIcon/>
                </IconButton>
            </Box>
        </Box>
    );
};

export default Topbar;
