import * as React from 'react';
import TEFGrid from "./TEFGrid";

export default function TEFComponent({ projectName, nodeId }) {
    return (
        <TEFGrid
            nodeId={nodeId}
            projectName={projectName}
        />
    );
}
