import * as React from 'react';
import VibrationTabPanel from "./VibrationTabPanel";
import {useEffect} from "react";
import TWFPlot from "./TWFPlot";
import ComparePlot from "./ComparePlot";
import FFTPlot from "./FFTPlot";

export default function VibrationTabs({ isDashboard, nodeId, projectName, graphType, setGraphType }) {
    const [tabId, setTabId] = React.useState(0);
    const [collectionTime, setCollectionTime] = React.useState(null);

    useEffect(() => {
    }, [collectionTime, graphType]);

    if (!graphType) {
        return (
            <VibrationTabPanel
                isDashboard={isDashboard}
                nodeId={nodeId}
                projectName={projectName}
                tabId={tabId}
                setTabId={setTabId}
                setGraphType={setGraphType}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
            />
        )
    } if (graphType === 'plot') {
        return (
            <TWFPlot
                nodeId={nodeId}
                projectName={projectName}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                setGraphType={setGraphType}
            />
        )
    } else if (graphType === 'fft') {
        return (
            <FFTPlot
                nodeId={nodeId}
                projectName={projectName}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                setGraphType={setGraphType}
            />
        )

    } else if (graphType === 'compare') {
        return (
            <ComparePlot
                nodeId={nodeId}
                projectName={projectName}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                setGraphType={setGraphType}
            />
        )
    }
}
