import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {authHeader} from "../../helpers";
import {API_GET_MEASUREMENT_LIST} from "../../constants/network";
import {Box} from "@mui/material";
import * as React from "react";


export default function VisualImage({projectName, nodeId, visualImageId, setCollectionTime, setVisualImageId}) {
    const dispatch = useDispatch();
    const [visualImage, setVisualImage] = useState([]);

    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                const componentId = nodeId.substring(2);
                const headers = authHeader(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/visualImage/${visualImageId}`);
                fetch(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/visualImage/${visualImageId}`, {
                    method: 'get',
                    headers: headers
                }).then(response => {
                    return response.blob();
                }).then(blob => {
                    setVisualImage(URL.createObjectURL(blob));
                });
            } else {
                setVisualImage(null);
            }
        }
    }, [dispatch, nodeId, projectName, visualImageId]);

    if (!visualImage) {
        return;
    }

    const handleClickButton = (event) => {
        event.stopPropagation();
        setVisualImageId(null);
        setCollectionTime(null)
    };

    return (
        <div>
            <button onClick={handleClickButton}>Close</button>
            <Box display="flex" height="3vh">
            </Box>
            <Box display="flex" height="70vh" m="-2px 0 0 0">
                <img src={visualImage} height="95%" width="auto" onClick={handleClickButton}/>
            </Box>
        </div>
    );
}