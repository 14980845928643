import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {measurementActions} from "../../store";
import {Box} from "@mui/material";
import {Chart} from "react-google-charts";
import * as React from "react";

export default function GenComparePlot({ nodeId, projectName, collectionTime, setCollectionTime, setGraphType }) {
    const dispatch = useDispatch();
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                projectName && dispatch(measurementActions.getTWFCompare({
                    projectName: projectName,
                    componentId: nodeId.substring(2),
                    collectionTime
                }))
                    .then((state) => {
                        setGraphData(state.payload);
                    });
            } else {
                setGraphData([]);
            }
        }
    }, [dispatch, nodeId, projectName]);

    if (!graphData || graphData.length === 0) {
        return;
    }
    const options = {
        chartArea: {width: "100%", left: "135", right: "30"},
        curveType: "",
        crosshair: { trigger: 'both' , color: 'green'},
        hAxis: {
            title: "time (S)",
            textStyle: {
                fontSize : 12
            },
            slantedText: true,
            slantedTextAngle: 45,
        },
        vAxis: {
            title: "G's",
            ticks: [
                0,
                {v: -2.0, f: 'Peak Y-Alert -2.0'},
                {v: -4.0, f: 'Peak R-Alert -4.0'},
                {v: 2.0, f: 'Peak Y-Alert 2.0'},
                {v: 4.0, f: 'Peak R-Alert 4.0'}
            ]
        },
        legend: { position: "top" },
        explorer: {
            actions: ['dragToZoom', 'rightClickToReset'],
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 50.0
        },
    };

    const handleClickButton = (event) => {
        event.stopPropagation();
        setGraphType(null);
        setCollectionTime(null)
    };

    return (
        <div>
            {/*<Box display="flex" height="7vh">*/}
            {/*    <PlotTabs*/}
            {/*    />*/}
            {/*</Box>*/}
            <button onClick={handleClickButton}>Close Plot</button>
            <Box display="flex" height="73vh" p="14px">
                <Chart
                    chartType="LineChart"
                    data={graphData}
                    options={options}
                    height="95%"
                    style={{flex: 2}}
                />
            </Box>
        </div>
    );

}