import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {authHeader} from "../../helpers";
import {API_GET_MEASUREMENT_LIST} from "../../constants/network";
import * as React from "react";

export default function GenVibrationImage({projectName, nodeId}) {
    const dispatch = useDispatch();
    const [vibrationImage, setVibrationImage] = useState([]);
    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                const componentId = nodeId.substring(2);
                const headers = authHeader(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/baselineImage`);
                fetch(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/baselineImage`, {
                    method: 'get',
                    headers: headers
                }).then(response => {
                    return response.blob();
                }).then(blob => {
                    setVibrationImage(URL.createObjectURL(blob));
                });
            } else {
                setVibrationImage(null);
            }
        }
    }, [dispatch, nodeId, projectName]);

    if (!vibrationImage) {
        return;
    }

    return (
        <div>
            <Box display="flex" height="69vh" m="-2px 0 0 0">
                <img src={vibrationImage} height="95%" width="auto"/>
            </Box>
        </div>
    )
}