import * as React from 'react';
import {useEffect, useState} from "react";
import VisualTapPanel from "./VisualTabPanel";
import VisualImage from "./VisualPlot";

export default function VisualComponent({ projectName, nodeId }) {
    const [tabId, setTabId] = React.useState(0);
    const [gridData, setGridData] = useState([]);
    const [visualImageId, setVisualImageId] = useState(null);
    const [collectionTime, setCollectionTime] = useState(null);

    useEffect(() => {
    }, [visualImageId, collectionTime]);

    if (!visualImageId) {
        return (
            <VisualTapPanel
                gridData={gridData}
                nodeId={nodeId}
                projectName={projectName}
                setGridData={setGridData}
                tabId={tabId}
                setTabId={setTabId}
                setVisualImageId={setVisualImageId}
                setCollectionTime={setCollectionTime}
            />
        );
    } else {
        return (
            <VisualImage
                nodeId={nodeId}
                projectName={projectName}
                visualImageId={visualImageId}
                setVisualImageId={setVisualImageId}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
            />
        );
    }
}
