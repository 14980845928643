import * as React from 'react';
import ElectricalGrid from "./ElectricalGrid";

export default function ElectricalComponent({ projectName, nodeId }) {
    return (
        <ElectricalGrid
            nodeId={nodeId}
            projectName={projectName}
        />
    );
}
