import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {fetchWrapper, objToQueryString} from '../../helpers';
import {API_ELECTRICAL_READING_PAGE} from "../../constants/network";


// create slice
const name = 'electricalReading';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const electricalReadingSlice = createSlice({name, initialState, extraReducers});

// exports

export const electricalReadingActions = {...electricalReadingSlice.actions, ...extraActions};

// implementation

function createInitialState() {
    return {}
}

function createExtraActions() {
    return {
        getElectricalReadingPage: getElectricalReadingPage(),
    };

    function getElectricalReadingPage() {
        return createAsyncThunk(
            `/electricalReading/get`,
            async ({
                       projectName,
                       componentId,
                       pageable,
                   }) => await fetchWrapper.get(`${API_ELECTRICAL_READING_PAGE}/${projectName}/${componentId}?${objToQueryString(pageable)}`, null)
        );
    }
}

function createExtraReducers() {
    return {
        ...getElectricalReadingPage(),
    };

    function getElectricalReadingPage() {
        let {pending, fulfilled, rejected} = extraActions.getElectricalReadingPage;
        return {
            [pending]: () => {
            },
            [fulfilled]: (state, action) => {
                return action.payload;
            },
            [rejected]: (state, action) => {
                return action;
            }
        };
    }
}

export default electricalReadingSlice;