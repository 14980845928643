import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import Moment from 'moment';
import {useEffect} from "react";
import {measurementActions} from "../../store";
import {useDispatch} from "react-redux";
import {Button} from "@mui/material";

export default function ThermalGrid({ projectName, nodeId, gridData, setGridData, setThermalImageId, setThermalImageStatsId, setCollectionTime, pageSize = 25}) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const handleClickPlotButton = (event, row) => {
        event.stopPropagation();
        setThermalImageId(row.id);
        setCollectionTime(row.collectionTime);
    };

    const handleClickStatsButton = (event, row) => {
        event.stopPropagation();
        setThermalImageStatsId(row.id);
        setCollectionTime(row.collectionTime);
    };

    const formatDec = {
        pattern: '0.0',
    };

    const columns = [
        {field: 'id', headerName: 'ID', width: 60, hide: true},
        {
            field: 'collectionTime',
            headerName: 'Timestamp',
            width: 160,
            editable: false,
            align:'center',
            headerAlign: "center",
            valueFormatter: params => new Moment(params?.value).format("YYYY/MM/DD HH:mm:ss"),
        },
        {
            field: 'minTemperature',
            headerName: 'Min Temp',
            type: 'number',
            numericFormat: formatDec,
            width: 100,
            editable: false,
            align:'center',
            headerAlign: "center",
            valueFormatter: ({ value }) => value ? value.toFixed(2) : '',
        },
        {
            field: 'avgTemperature',
            headerName: 'Avg Temp',
            type: 'number',
            numericFormat: formatDec,
            width: 100,
            editable: false,
            align:'center',
            headerAlign: "center",
            valueFormatter: ({ value }) => value ? value.toFixed(2) : '',
        },
        {
            field: 'maxTemperature',
            headerName: 'Max Temp',
            type: 'number',
            numericFormat: formatDec,
            width: 100,
            editable: false,
            align:'center',
            headerAlign: "center",
            valueFormatter: ({ value }) => value ? value.toFixed(2) : '',
        },
        { field: 'plot', headerName: '', width: 120, renderCell: (params) => {
                return (
                    <Button
                        onClick={(e) => handleClickPlotButton(e, params.row)}
                        variant="contained"
                        // color="primary"
                        style={{
                            backgroundColor: "#4169E1",
                            fontSize: "12px",
                            margin: "4px",
                            alignItems: "center"
                        }}
                    >
                        IR-Image
                    </Button>
                );
            }
        },
        { field: 'stats', headerName: '', width: 120, renderCell: (params) => {
                return (
                    <Button
                        onClick={(e) => handleClickStatsButton(e, params.row)}
                        variant="contained"
                        // color="primary"
                        style={{
                            backgroundColor: "#4169E1",
                            fontSize: "12px",
                            margin: "4px",
                            alignItems: "center"
                        }}
                    >
                        IR-Stats
                    </Button>
                );
            }
        },
    ];

    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0,2) === "C-") {
                setIsLoading(true);
                projectName && dispatch(measurementActions.getThermalList({
                    projectName: projectName,
                    componentId: nodeId.substring(2)
                }))
                    .then((state) => {
                        setGridData(state.payload);
                        setIsLoading(false);
                    });
            } else {
                setGridData([]);
            }
        }
    }, [dispatch, nodeId, projectName]);

    if (!gridData) {
        return;
    }

    return (
        <div>
            <Box height="69vh" m="-10px 0 0 0">
                <DataGrid
                    loading={isLoading}
                    rows={gridData}
                    columns={columns}

                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: {pageSize},
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'collectionTime', sort: 'desc' }],
                        },
                    }}

                    sx={{
                        '& .normal': {
                        },
                        '& .warning': {
                            backgroundColor: '#cccc0022',
                        },
                        '& .danger': {
                            backgroundColor: '#ff000022',
                        },
                    }}

                    components={{ Toolbar: GridToolbar }}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
        </div>
    );
}
