import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {fetchWrapper, objToQueryString} from '../../helpers';
import {
    API_GET_ACCOUNT_PROJECTS,
    API_GET_ACCOUNT_PROJECTS_PAGES,
    API_GET_PROJECT_HIERARCHY, API_GET_PROJECTS_PAGES
} from "../../constants/network";

// create slice
const name = 'project';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const projectSlice = createSlice({ name, initialState, extraReducers });

// exports

export const projectActions = { ...projectSlice.actions, ...extraActions };

function createInitialState() {
    return {
        projectList: []
    }
}

function createExtraActions() {
    return {
        getProjectList: getProjectList(),
        getProjectPage: getProjectPage(),
        getHierarchy: getHierarchy()
    };

    function getProjectList() {
        return createAsyncThunk(
            '/accounts/project',
            async () => await fetchWrapper.get(`${API_GET_ACCOUNT_PROJECTS}`, null)
        );
    }
    function getProjectPage() {
        return createAsyncThunk(
            '/accounts/project/pages',
            async ({ pageable }) => await fetchWrapper.get(`${API_GET_PROJECTS_PAGES}`+ `?${objToQueryString(pageable)}`, null)
        );
    }
    function getHierarchy() {
        return createAsyncThunk(
            "/projects",
            async ({projectName}) => await fetchWrapper.get(`${API_GET_PROJECT_HIERARCHY}/${projectName}/hierarchy`, null)
        );
    }
}

function createExtraReducers() {
    return {
        ...getProjectList(),
        ...getProjectPage(),
        ...getHierarchy()
    };

    function getProjectList() {
        let { pending, fulfilled, rejected } = extraActions.getProjectList;
        return {
            [pending]: (state) => {
                state.projectList = [];
            },
            [fulfilled]: (state, action) => {
                state.projectList = action.payload;
                return state;
            },
            [rejected]: (state, action) => {
                state.projectList = { error: action.error };
            }
        };
    }

    function getProjectPage() {
        let { pending, fulfilled, rejected } = extraActions.getProjectPage;

        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state) => {
                return state;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            },
        };
    }

    function getHierarchy() {
        let { pending, fulfilled, rejected } = extraActions.getHierarchy;
        return {
            [pending]: () => {
                           },
            [fulfilled]: (state) => {
                return state;
            },
            [rejected]: (state, action) => {
                state.projectList = { error: action.error };
            }
        };
    }
}

export default projectSlice;