import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {authHeader} from "../../helpers";
import {API_GET_MEASUREMENT_LIST, API_GET_THERMALDATA} from "../../constants/network";
import {Box, CircularProgress, LinearProgress} from "@mui/material";
import * as React from "react";
import PlotTabs from "../component/TabPlots";


export default function ThermalImage({projectName, nodeId, thermalImageId, setCollectionTime, setThermalImageId}) {
    const dispatch = useDispatch();
    const [thermalImage, setThermalImage] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                const componentId = nodeId.substring(2);
                const headers = authHeader(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/thermalImage/${thermalImageId}`);
                fetch(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/thermalImage/${thermalImageId}`, {
                    method: 'get',
                    headers: headers
                }).then(response => {
                    return response.blob();
                }).then(blob => {
                    setThermalImage(URL.createObjectURL(blob));
                    setLoading(false);
                });
            } else {
                setThermalImage(null);
            }
        }
    }, [dispatch, nodeId, projectName, thermalImageId]);

    if (!thermalImage) {
        return;
    }

    const handleClickButton = (event) => {
        event.stopPropagation();
        setThermalImageId(null);
        setCollectionTime(null)
    };

    const handleClickButtonMSX = (event) => {
        setLoading(true);
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                const componentId = nodeId.substring(2);
                const headers = authHeader(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/thermalImage/${thermalImageId}`);
                fetch(`${API_GET_MEASUREMENT_LIST}/${projectName}/${componentId}/thermalImage/${thermalImageId}`, {
                    method: 'get',
                    headers: headers
                }).then(response => {
                    return response.blob();
                }).then(blob => {
                    setThermalImage(URL.createObjectURL(blob));
                    setLoading(false);
                });
            } else {
                setThermalImage(null);
            }
        }
    };

    const handleClickButtonIR = (event) => {
        setLoading(true);
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                const componentId = nodeId.substring(2);
                const headers = authHeader(`${API_GET_THERMALDATA}/${projectName}/${componentId}/${thermalImageId}?type=ThermalImage`);
                fetch(`${API_GET_THERMALDATA}/${projectName}/${componentId}/${thermalImageId}?type=ThermalImage`, {
                    method: 'get',
                    headers: headers
                }).then(response => {
                    return response.blob();
                }).then(blob => {
                    setThermalImage(URL.createObjectURL(blob));
                    setLoading(false);
                });
            } else {
                setThermalImage(null);
            }
        }
    };

    const handleClickButtonVisual = (event) => {
        setLoading(true);
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                const componentId = nodeId.substring(2);
                const headers = authHeader(`${API_GET_THERMALDATA}/${projectName}/${componentId}/${thermalImageId}?type=RgbImage`);
                fetch(`${API_GET_THERMALDATA}/${projectName}/${componentId}/${thermalImageId}?type=RgbImage`, {
                    method: 'get',
                    headers: headers
                }).then(response => {
                    return response.blob();
                }).then(blob => {
                    setThermalImage(URL.createObjectURL(blob));
                    setLoading(false);
                });
            } else {
                setThermalImage(null);
            }
        }
    };

    return (
        <div>
            <button onClick={handleClickButton}>Close</button>
            {"  "}
            <button onClick={handleClickButtonMSX}>MSX Image</button>
            {"  "}
            <button onClick={handleClickButtonIR}>IR Image</button>
            {"  "}
            <button onClick={handleClickButtonVisual}>Visual Img</button>
            <Box display="flex" height="3vh">
            </Box>
            <div style={{display: loading ? "block" : "none"}}>
                <LinearProgress/>
            </div>
            <div style={{display: loading ? "none" : "block"}}>
                <Box display="flex" height="70vh" m="-2px 0 0 0">
                <img src={thermalImage} height="95%" width="auto"/>
                {/*<img src={thermalImage} height="95%" width="auto" onClick={handleClickButton}/>*/}
            </Box>
            </div>
        </div>
    );
}