import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../../helpers";
import {
  API_ADMIN,
  API_ADMIN_ACCOUNTS,
  API_ADMIN_ROLES,
} from "../../constants/network";

// Create Slice:
const name = "public";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const adminSlice = createSlice({ name, initialState, extraReducers });

// Exports:
export const adminActions = { ...adminSlice.actions, ...extraActions };

// Implementation:
function createInitialState() {
  return {
    pageable: {
      page: 0,
      size: 25,
    },
  };
}

function objToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(
      encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
    );
  }
  return keyValuePairs.join("&");
}

function createExtraActions() {
  function accountList() {
    return createAsyncThunk(
      "/admin/accounts",
      async ({ pageable }) =>
        await fetchWrapper.get(
          API_ADMIN_ACCOUNTS + `?${objToQueryString(pageable)}`,
          null
        )
    );
  }

  function accountCreate() {
    return createAsyncThunk(
      "/admin/accounts/create",
      async ({ projectId, body }) =>
        await fetchWrapper.post(`${API_ADMIN}/${projectId}/accounts`, body)
    );
  }

  function accountGet() {
    return createAsyncThunk(
      "/admin/accounts/get",
      async ({ projectId, accountId }) =>
        await fetchWrapper.get(
          `${API_ADMIN}/${projectId}/accounts/${accountId}`,
          null
        )
    );
  }

  function accountUpdate() {
    return createAsyncThunk(
      "/admin/accounts/update",
      async ({ projectId, accountId, body }) =>
        await fetchWrapper.put(
          `${API_ADMIN}/${projectId}/accounts/${accountId}`,
          body
        )
    );
  }

  function roleTypeList() {
    return createAsyncThunk(
      "/admin/roles",
      async () => await fetchWrapper.get(API_ADMIN_ROLES, null)
    );
  }

  return {
    accountList: accountList(),
    accountCreate: accountCreate(),
    accountGet: accountGet(),
    accountUpdate: accountUpdate(),
    roleTypeList: roleTypeList(),
  };
}

function createExtraReducers() {
  function accountList() {
    let { pending, fulfilled, rejected } = extraActions.accountList;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function accountCreate() {
    let { pending, fulfilled, rejected } = extraActions.accountCreate();

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function accountGet() {
    let { pending, fulfilled, rejected } = extraActions.accountGet;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function accountUpdate() {
    let { pending, fulfilled, rejected } = extraActions.accountUpdate();

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function roleTypeList() {
    let { pending, fulfilled, rejected } = extraActions.roleTypeList();

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  return {
    ...accountList(),
    ...accountCreate(),
    ...accountGet(),
    ...accountUpdate(),
    ...roleTypeList(),
  };
}

export default adminSlice;
