import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";

export default function Copyright(props) {
    return (
        <Typography
            align="center"
            color="text.secondary"
            variant="body2"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://sens-os.com">
                SensOS, LLC
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}