import {
  API_PUBLIC_ACTIVATION_INIT,
  API_PUBLIC_ACTIVATION_FINISH,
  API_PUBLIC_PASSWORD_RESET,
  API_PUBLIC_PASSWORD_RESET_INIT,
  API_PUBLIC_PASSWORD_RESET_FINISH,
} from "../../constants/network";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../helpers";

// Create Slice:
const name = "public";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const publicSlice = createSlice({ name, initialState, extraReducers });

// Exports:
export const publicActions = { ...publicSlice.actions, ...extraActions };

// Implementation:
function createInitialState() {
  return {};
}

function createExtraActions() {
  function activationInit() {
    return createAsyncThunk(
      "/public/activationInit",
      async ({ resetKey }) =>
        await fetchWrapper.post(
          `${API_PUBLIC_ACTIVATION_INIT}/${resetKey}`,
          null
        )
    );
  }

  function activationFinish() {
    return createAsyncThunk(
      "/public/activationFinish",
      async ({ email, password, resetKey, resetCode }) =>
        await fetchWrapper.post(API_PUBLIC_ACTIVATION_FINISH, {
          email,
          password,
          resetKey,
          resetCode,
        })
    );
  }

  function passwordReset() {
    return createAsyncThunk(
      "/public/passwordReset",
      async ({ email }) =>
        await fetchWrapper.post(API_PUBLIC_PASSWORD_RESET, { email })
    );
  }

  function passwordResetInit() {
    return createAsyncThunk(
      "/public/passwordResetInit",
      async ({ resetKey }) =>
        await fetchWrapper.post(
          `${API_PUBLIC_PASSWORD_RESET_INIT}/${resetKey}`,
          null
        )
    );
  }

  function passwordResetFinish() {
    return createAsyncThunk(
      "/public/passwordResetFinish",
      async ({ email, password, resetKey, resetCode }) =>
        await fetchWrapper.post(API_PUBLIC_PASSWORD_RESET_FINISH, {
          email,
          password,
          resetKey,
          resetCode,
        })
    );
  }

  return {
    activationInit: activationInit(),
    activationFinish: activationFinish(),
    passwordReset: passwordReset(),
    passwordResetInit: passwordResetInit(),
    passwordResetFinish: passwordResetFinish(),
  };
}

function createExtraReducers() {
  function activationInit() {
    let { pending, fulfilled, rejected } = extraActions.activationInit;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function activationFinish() {
    let { pending, fulfilled, rejected } = extraActions.activationFinish;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function passwordReset() {
    let { pending, fulfilled, rejected } = extraActions.passwordReset;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function passwordResetInit() {
    let { pending, fulfilled, rejected } = extraActions.passwordResetInit;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function passwordResetFinish() {
    let { pending, fulfilled, rejected } = extraActions.passwordResetFinish;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  return {
    ...activationInit(),
    ...activationFinish(),
    ...passwordReset(),
    ...passwordResetInit(),
    ...passwordResetFinish(),
  };
}

export default publicSlice;
