import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {measurementActions} from "../../store";
import {Chart} from "react-google-charts";

export default function BaselinePlot({projectName, nodeId}) {
    const dispatch = useDispatch();
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                projectName && dispatch(measurementActions.getBaselineData({
                    projectName: projectName,
                    componentId: nodeId.substring(2)
                }))
                    .then((state) => {
                        setGraphData(state.payload);
                    });
            } else {
                setGraphData([]);
            }
        }
    }, [dispatch, nodeId, projectName]);

    if (!graphData || graphData.length === 0) {
        return;
    }

    const options = {
        curveType: "",
        crosshair: { trigger: 'both' , color: 'red'},
        chartArea: {width: "100%", left: "135", right: "30"},
        hAxis: { title: "time (s)" },
        vAxis: { title: "G's",
            ticks: [
                0,
                {v: -2.0, f: 'Peak Y-Alert -2.0'},
                {v: -4.0, f: 'Peak R-Alert -4.0'},
                {v: 2.0, f: 'Peak Y-Alert 2.0'},
                {v: 4.0, f: 'Peak R-Alert 4.0'}
            ]
        },
        legend: { position: "top" },
        explorer: {
            actions: ['dragToZoom', 'rightClickToReset'],
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 50.0
        },
    };

    return (
        <div>
            <Box display="flex" height="70vh" m="-2px 0 0 0">
                <Chart
                    chartType="LineChart"
                    data={graphData}
                    options={options}
                    height="96%"
                    style={{ flex: 2 }}
                    // toolbarItems={[
                    //     {
                    //         type: "csv",
                    //         datasource: `https://reliableplane.s3.amazonaws.com/${projectName}/Temp/${nodeId.substring(2)}.csv`
                    //     }
                    //     ]}
                />
            </Box>
        </div>
    )
}