import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {authHeader} from "../../helpers";
import {API_GET_MEASUREMENT_LIST, API_GET_THERMALDATA} from "../../constants/network";
import {Box, Button, CircularProgress, LinearProgress} from "@mui/material";
import * as React from "react";
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import Moment from "moment/moment";
import {number} from "yup";


export default function ThermalStats({projectName, nodeId, thermalImageStatsId, setCollectionTime, setThermalImageStatsId}) {
    const dispatch = useDispatch();
    const [thermalStats, setThermalStats] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                const componentId = nodeId.substring(2);
                const headers = authHeader(`${API_GET_THERMALDATA}/${projectName}/${componentId}/${thermalImageStatsId}?type=Csv`);
                fetch(`${API_GET_THERMALDATA}/${projectName}/${componentId}/${thermalImageStatsId}?type=Csv`, {
                    method: 'get',
                    headers: headers
            }).then(response => {
                return response.blob();
            }).then(blob => {
                    setThermalStats(URL.createObjectURL(blob));
                    setLoading(false);
                });
        } else {
                setThermalStats(null);
        }
        }
    }, [dispatch, nodeId, projectName, thermalImageStatsId]);

    if (!thermalStats) {
        return;
    }

    const handleClickButton = (event) => {
        event.stopPropagation();
        setThermalImageStatsId(null);
        setCollectionTime(null)
    };

    const formatDec = {
        pattern: '0.0',
    };

    const columns = [
        {field: 'id', headerName: 'ID', width: 60, hide: true},
        {
            field: 'name',
            headerName: 'Var',
            width: 100,
            editable: false,
            align:'center',
            headerAlign: "center",
        },
        {
            field: 'value',
            headerName: 'Value',
            width: 100,
            editable: false,
            align:'center',
            headerAlign: "center",
            valueFormatter: ({ value }) => value ? value.toFixed(2) : '',
        },
    ];

    var gridData = [
        { id:1, name: "# Pixels", value:calc_size() },
        { id:2, name: "Avg Temp", value:calc_size() }
    ]

    function calc_size() {
        var storedText;
        fetch(thermalStats)
            .then(function(response) {
                response.blob().then(function(blob) {
                    storedText = blob;
                });
            });
        var lines = storedText
        return lines
    }

    function calcStats() {
        const finalArray = [];
        const csvArray = thermalStats.split('\n').slice(2)
        csvArray.forEach(row => {
            const colums = row.split('\t');
            const temp = colums[2];
            finalArray.push([temp]);
        })
        var total = 0;
        for(var i = 0; i < finalArray.length; i++) {
            total += finalArray[i];
        }
        var avg = total / finalArray.length;
    }

    // const handleClickButtonGetStats = (event) => {
    //     calcStats();
    // }

    const handleClickButtonDownloadCsv = (event) => {
        window.open(thermalStats, '_blank');
    }

    return (
        <div>
            <button onClick={handleClickButton}>Close</button>
            {"  "}
            {/*<button onClick={handleClickButtonGetStats}>Stats</button>*/}
            {/*{"  "}*/}
            <button onClick={handleClickButtonDownloadCsv}>Download CSV</button>
            <Box display="flex" height="3vh">
            </Box>
            <div style={{display: loading ? "block" : "none"}}>
                <LinearProgress/>
            </div>
            <div style={{display: loading ? "none" : "block"}}>
                <Box display="flex" height="70vh" m="-2px 0 0 0">
                    <DataGrid
                        rows={gridData}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        rowsPerPageOptions={[5, 10]}
                        checkboxSelection
                    />
                {/*    /!*<img src={thermalStats} height="95%" width="auto"/>*!/*/}
            </Box>
            </div>
        </div>
    );
}