import AccountForm from "./scenes/accounts/accountForm";
import Accounts from "./scenes/accounts";
import {
  ACTION_ACCOUNT_CHANGE_PASSWORD,
  ACTION_ACCOUNT_UPDATE,
  ACTION_ACCOUNT_VIEW,
  ACTION_CREATE,
  ACTION_UPDATE,
  ACTION_VIEW,
} from "./constants/actions";
import Activation from "./scenes/activation";
import { ClearCacheProvider } from "react-clear-cache";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Dashboard from "./scenes/dashboard";
import ForgotPassword from "./scenes/forgotPassword";
import Login from "./scenes/login";
import RecoverPassword from "./scenes/recoverPassword";
import { Routes, Route, Navigate } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import ProtectedRoute from "./util/ProtectedRoute";
import PublicRoute from "./util/PublicRoute";
import Projects from "./scenes/projects";

function App() {
  const [theme, colorMode] = useMode();

  return (
      <ClearCacheProvider duration={60000} auto='true'>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline/>
            <SnackbarProvider/>
            <Routes>

              <Route element={<ProtectedRoute/>}>
                <Route path="/" element={<Navigate to="/dashboard" replace={true}/>}/>
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="/account/change-password"
                       element={<AccountForm action={ACTION_ACCOUNT_CHANGE_PASSWORD}/>}/>
                <Route path="/account/update" element={<AccountForm action={ACTION_ACCOUNT_UPDATE}/>}/>
                <Route path="/account/view" element={<AccountForm action={ACTION_ACCOUNT_VIEW}/>}/>

                <Route path="/admin/accounts" element={<Accounts/>}/>
                <Route path="/admin/accounts/create" element={<AccountForm action={ACTION_CREATE}/>}/>
                <Route path="/admin/:projectId/accounts/:accountId" element={<AccountForm action={ACTION_VIEW}/>}/>
                <Route path="/admin/:projectId/accounts/:accountId/update"
                       element={<AccountForm action={ACTION_UPDATE}/>}/>

                <Route path="/admin/projects" element={<Projects/>}/>
              </Route>

              <Route element={<PublicRoute/>}>
                <Route path="/login" element={<Login/>}/>
                <Route path="/activation/:resetKey" element={<Activation/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/recover-password/:resetKey" element={<RecoverPassword/>}/>
              </Route>

            </Routes>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </ClearCacheProvider>
  );
}

export default App;
