import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import Moment from 'moment';
import {useEffect, useState} from "react";
import {electricalReadingActions} from "../../store";
import {useDispatch} from "react-redux";

export default function ElectricalGrid({ projectName, nodeId}) {
    const dispatch = useDispatch();
    const [gridData, setGridData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState("collectionTime,desc");
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const handleSortModelChange = (sortModel) => {
        if (sortModel && sortModel.length > 0) {
            const orderBy = sortModel[0].field;
            const order = sortModel[0].sort;
            setSort(orderBy + "," + order);
        }
    };

    const columns = [
        {field: 'id', headerName: 'ID', width: 60, hide: true},
        {
            field: 'collectionTime',
            headerName: 'Timestamp',
            width: 160,
            editable: false,
            align:'center',
            headerAlign: "center",
            valueFormatter: params => new Moment(params?.value).format("YYYY/MM/DD HH:mm:ss"),
        },
        {
            field: 'A1',
            headerName: 'A1',
            align:'left',
            headerAlign: "center",
            width: 200,
            editable: false,
        },
        {
            field: 'A2',
            headerName: 'A2',
            align: 'left',
            headerAlign: "center",
            width: 200,
            editable: false,
        },
        {
            field: 'A3',
            headerName: 'A3',
            align:'left',
            headerAlign: "center",
            width: 200,
            editable: false,
        }
    ];

    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                setIsLoading(true);
                let pageable = { size: pageSize, page, sort };
                projectName && dispatch(electricalReadingActions.getElectricalReadingPage({
                    projectName: projectName,
                    componentId: nodeId.substring(2),
                    pageable
                }))
                    .then((state) => {
                        if (state && state.payload) {
                            setIsLoading(false);
                            setGridData(state.payload.content);
                            setRowCount(state.payload.totalElements);
                        } else {
                            setGridData([]);
                            setRowCount(0);
                        }
                        setIsLoading(false);
                    });
            } else {
                setGridData([]);
                setIsLoading(false);
            }
        }
    }, [dispatch, nodeId, projectName]);

    return (
        <div>
            <Box height="69vh" m="-10px 0 0 0">
                <DataGrid
                    loading={isLoading}
                    rows={gridData}
                    columns={columns}
                    onSortModelChange={handleSortModelChange}
                    sortingMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    pagination
                    paginationMode="server"
                    pageSize={pageSize}
                    rowCount={rowCount}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: {pageSize},
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'collectionTime', sort: 'desc' }],
                        },
                    }}

                    sx={{
                        '& .normal': {
                        },
                        '& .warning': {
                            backgroundColor: '#cccc0022',
                        },
                        '& .danger': {
                            backgroundColor: '#ff000022',
                        },
                    }}

                    components={{ Toolbar: GridToolbar }}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
        </div>
    );
}
