import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store";
import { Navigate, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import * as React from "react";
import { useState } from "react";
import Copyright from "../../components/Copyright";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);

  // Backdrop {{
  const [backdropOpen, setBackdropOpen] = useState(false);
  // }} Backdrop

  const handleClose = () => {
    setOpen(false);
  };

  function onClickForgotPassword() {
    navigate("/forgot-password");
    window.location.reload();
  }

  function onSubmit({ email, password }) {
    setBackdropOpen(true);

    dispatch(authActions.login({ email, password })).then((data) => {
      if (data) {
        setBackdropOpen(false);

        if (data.type === "/authorization/token/fulfilled") {
          navigate("/");
          window.location.reload();
        } else if (data.type === "/authorization/token/rejected") {
          setOpen(true);
        }
      }
    });
  }

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflow: "hidden",
        }}
        open={backdropOpen}
      >
        <CircularProgress sx={{ color: "#1361A1" }} />
      </Backdrop>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message="User could not be authenticated"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        ContentProps={{
          sx: {
            background: "red",
          },
        }}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{ m: 1, bgcolor: "secondary.main", height: 80, width: 80 }}
          >
            {/*<LockOutlinedIcon fontSize="large"/>*/}
            <img
              alt="reliable_plane"
              width="80px"
              height="80px"
              src={`../../icon192.png`}
            />
          </Avatar>
          <Typography
            component="h1"
            variant="h3"
            sx={{ marginTop: 2, marginBottom: 3 }}
          >
            Sign in to ReliablePlane™
          </Typography>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mt: 1 }}>
                  <TextField
                    autoFocus
                    error={!!touched.email && !!errors.email}
                    fullWidth
                    helperText={touched.email && errors.email}
                    id="email"
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                  />
                  <TextField
                    error={!!touched.password && !!errors.password}
                    fullWidth
                    helperText={touched.password && errors.password}
                    id="password"
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    type="password"
                    value={values.password}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 5, mb: 3 }}
                  >
                    Sign In
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link onClick={onClickForgotPassword} variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </div>
  );
};

const checkoutSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("required"),
  password: yup.string().required("required"),
});
const initialValues = {
  email: "",
  password: "",
};

export default Login;
