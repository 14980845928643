import {useEffect, useState} from "react";
import {ProSidebar, Menu, MenuItem} from "react-pro-sidebar";
import {Box, IconButton, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {tokens} from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import {PeopleOutlined} from "@mui/icons-material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';

import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import {accountActions, settingsActions} from "../../store";

const Item = ({title, to, icon, selected, setSelected}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to}/>
        </MenuItem>
    );
};

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const account = useSelector((state) => state.account);
    const settings = useSelector((state) => state.settings);
    const [firstName, setFirstName] = useState(account.firstName);
    const [lastName, setLastName] = useState(account.lastName);
    const [roleName, setRoleName] = useState('');
    const [selected, setSelected] = useState("Dashboard");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(settingsActions.settingsGet())
            .then(() => {
                dispatch(accountActions.accountGet())
                    .then((state) => {
                        if (state.payload) {
                            setFirstName(state.payload.firstName);
                            setLastName(state.payload.lastName)
                            if (state.payload.roleList) {
                                setRoleName(state.payload.roleList[0]);
                            }
                        }
                    });
            });
    }, [dispatch]);

    const onToggleMenu = (toggleValue) => {
        const body = { menuHidden: toggleValue, darkTheme: settings.darkTheme};
        dispatch(settingsActions.settingsUpdate({ body }))
            .then(() => {
            });
    }
    
    const goToAccountView = () => {
      navigate("/account/view");  
    };

    return (
        <Box
            sx={{
                mb: 2,
                height: "100vh",
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#868dfb !important",
                },
                "& .pro-menu-item.active": {
                    color: "#6870fa !important",
                },
            }}
        >
            <ProSidebar collapsed={settings.menuHidden}
                        sx={{
                                height: "100vh"
                            }}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => onToggleMenu(!settings.menuHidden)}
                        icon={settings.menuHidden ? <MenuOutlinedIcon/> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!settings.menuHidden && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography variant="h3" color={colors.grey[100]}>
                                    ReliablePlane™
                                </Typography>
                                <IconButton onClick={() => onToggleMenu(!settings.menuHidden)}>
                                    <MenuOutlinedIcon/>
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {!settings.menuHidden && (
                        <Box mb="25px" onClick={() => goToAccountView()} sx={{cursor: "pointer"}}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={`../../assets/user.png`}
                                    style={{borderRadius: "50%"}}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    variant="h3"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{m: "10px 0 0 0"}}
                                >
                                    {firstName} {lastName}
                                </Typography>
                                <Typography variant="h5" color={colors.greenAccent[500]}>
                                    {roleName}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box paddingLeft={settings.menuHidden ? undefined : "10%"}>
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOutlinedIcon/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        { roleName && roleName === 'SystemAdmin' &&
                            <Item
                                title="Accounts"
                                to="/admin/accounts"
                                icon={<PeopleOutlined/>}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        }
                        { roleName && roleName === 'SystemAdmin' &&
                            <Item
                                title="Projects"
                                to="/admin/projects"
                                icon={<WorkspacesOutlinedIcon/>}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        }
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
