import { accountActions, adminActions, projectActions } from "../../store";
import {
  ACTION_ACCOUNT_CHANGE_PASSWORD,
  ACTION_ACCOUNT_UPDATE,
  ACTION_ACCOUNT_VIEW,
  ACTION_CREATE,
  ACTION_UPDATE,
  ACTION_VIEW,
} from "../../constants/actions";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import Sidebar from "../global/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { tokens } from "../../theme";
import Topbar from "../global/Topbar";
import Typography from "@mui/material/Typography";

import * as React from "react";
import * as yup from "yup";

const AccountForm = ({ action }) => {
  const navigate = useNavigate();

  // Backdrop {{
  const [backdropOpen, setBackdropOpen] = useState(false);
  // }} Backdrop

  // Snackbar {{
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarBackground, setSnackbarBackground] = useState("green");
  const [snackbarRedirect, setSnackbarRedirect] = useState(false);
  const [snackbarRedirectPath, setSnackbarRedirectPath] = useState();
  const [accountsPath] = useState("/admin/accounts");
  const [accountPath] = useState("/account/view");

  const snackbarOnClose = () => {
    setOpen(false);

    if (snackbarRedirect) {
      navigate(snackbarRedirectPath);
      window.location.reload();
    }
  };

  const showSnackbar = ({ msg, path, error }) => {
    if (!!error) {
      setSnackbarMessage(error);
      setSnackbarBackground("red");
      setSnackbarRedirect(false);
    } else {
      setSnackbarMessage(msg);
      setSnackbarBackground("green");
      setSnackbarRedirect(true);
      setSnackbarRedirectPath(path);
    }

    setOpen(true);
  };
  // }} Snackbar

  const [isSidebar, setIsSidebar] = useState(true);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Prepare Data {{
  const [projectList, setProjectList] = useState([]);
  useEffect(() => {
    dispatch(projectActions.getProjectList()).then((state) => {
      if (state.payload) {
        setProjectList(state.payload);
      }
    });
  }, [dispatch]);

  const [roleTypeList, setRoleTypeList] = useState([]);
  useEffect(() => {
    dispatch(adminActions.roleTypeList()).then((state) => {
      if (state.payload) {
        setRoleTypeList(
          state.payload.map((roleType) => ({
            id: roleType,
            name: roleType,
          }))
        );
      }
    });
  }, [dispatch]);

  const accountStatusList = [
    { id: "Unverified", name: "Unverified" },
    { id: "Inactive", name: "Inactive" },
    { id: "Active", name: "Active" },
    { id: "Locked", name: "Locked" },
  ];

  const { projectId, accountId } = useParams();
  const { account: selfAccount } = useSelector((state) => state.account);
  const [account, setAccount] = useState();
  const [initialValues, setInitialValues] = useState(getInitialValues());
  useEffect(() => {
    if (!!projectId && !!accountId) {
      dispatch(adminActions.accountGet({ projectId, accountId })).then(
        (state) => {
          if (state.payload) {
            setAccount(state.payload);
            setInitialValues(getInitialValues(action, state.payload));
          }
        }
      );
    } else {
      setAccount(selfAccount);
      setInitialValues(getInitialValues(action, selfAccount));
    }
  }, [dispatch, projectId, accountId, action, selfAccount]);
  // }} Prepare Data

  // Form {{
  const hiddenFor = (actions) =>
    actions.includes(action) ? { display: { xs: "none" }, xs: "6" } : {};
  const hiddenForAccountUpdateChangePassword = () =>
    hiddenFor([ACTION_ACCOUNT_UPDATE, ACTION_ACCOUNT_CHANGE_PASSWORD]);
  const hiddenForAccountView = () => hiddenFor([ACTION_ACCOUNT_VIEW]);
  const hiddenForChangePassword = () =>
    hiddenFor([ACTION_ACCOUNT_CHANGE_PASSWORD]);
  const hiddenForView = () => hiddenFor([ACTION_VIEW, ACTION_ACCOUNT_VIEW]);

  const showFor = (actions) =>
    !actions.includes(action) ? { display: { xs: "none" }, xs: "6" } : {};
  const showForCreate = () => showFor([ACTION_CREATE]);
  const showForViewAccountView = () =>
    showFor([ACTION_VIEW, ACTION_ACCOUNT_VIEW]);
  const showForAccountView = () => showFor([ACTION_ACCOUNT_VIEW]);
  const showForChangePassword = () => showFor([ACTION_ACCOUNT_CHANGE_PASSWORD]);

  const readOnlyFor = (actions) => actions.includes(action);
  const readOnlyForViewAccountView = () =>
    readOnlyFor([ACTION_VIEW, ACTION_ACCOUNT_VIEW]);
  // }} Form

  // Form Buttons {{
  const cancelButtonLabel = (action) => {
    return [ACTION_VIEW, ACTION_ACCOUNT_VIEW].includes(action)
      ? "Back"
      : "Cancel";
  };

  const cancel = () => {
    return navigate(-1);
  };

  const getUpdatePath = () => {
    return ACTION_VIEW === action
      ? `/admin/${account?.projectList[0]?.id}/accounts/${account?.id}/update`
      : "/account/update";
  };

  const submitButtonLabel = (action) => {
    if (ACTION_CREATE === action) {
      return "Create";
    } else if ([ACTION_UPDATE, ACTION_ACCOUNT_UPDATE].includes(action)) {
      return "Update";
    } else if (ACTION_ACCOUNT_CHANGE_PASSWORD === action) {
      return "Change Password";
    }

    return "Submit";
  };

  const submit = ({
    id,
    firstName,
    lastName,
    userName,
    email,
    roleType,
    project,
    accountStatus,
    oldPassword,
    newPassword,
  }) => {
    setBackdropOpen(true);

    if (ACTION_CREATE === action) {
      const body = {
        firstName,
        lastName,
        userName,
        email,
        roleType: roleType.id,
        accountStatus: accountStatus.id,
      };
      dispatch(
        adminActions.accountCreate({ projectId: project.id, body })
      ).then((data) => {
        setBackdropOpen(false);

        if (data && data.meta && data.meta.requestStatus === "fulfilled") {
          showSnackbar({
            msg: "Create account successful",
            path: accountsPath,
          });
        } else {
          showSnackbar({ error: "Error account could not be created" });
        }
      });
    } else if (ACTION_UPDATE === action) {
      const body = {
        firstName,
        lastName,
        userName,
        email,
        roleType: roleType.id,
        accountStatus: accountStatus.id,
      };
      dispatch(
        adminActions.accountUpdate({ projectId, accountId: id, body })
      ).then((data) => {
        setBackdropOpen(false);

        if (data && data.meta && data.meta.requestStatus === "fulfilled") {
          showSnackbar({
            msg: "Update account successful",
            path: accountsPath,
          });
        } else {
          showSnackbar({ error: "Error account could not be updated" });
        }
      });
    } else if (ACTION_ACCOUNT_UPDATE === action) {
      const body = {
        firstName,
        lastName,
        userName,
        email,
      };
      dispatch(accountActions.accountUpdate({ body })).then((data) => {
        setBackdropOpen(false);

        if (data && data.meta && data.meta.requestStatus === "fulfilled") {
          showSnackbar({
            msg: "Update account successful",
            path: accountPath,
          });
        } else {
          showSnackbar({ error: "Error account could not be updated" });
        }
      });
    } else if (ACTION_ACCOUNT_CHANGE_PASSWORD === action) {
      const body = {
        oldPassword,
        newPassword,
      };
      dispatch(accountActions.accountChangePassword({ body })).then((data) => {
        setBackdropOpen(false);

        if (data && data.meta && data.meta.requestStatus === "fulfilled") {
          showSnackbar({
            msg: "Change password successful",
            path: accountPath,
          });
        } else {
          showSnackbar({ error: "Error password could not be changed" });
        }
      });
    }
  };
  // }} Form Buttons

  return (
    <div className="app">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflow: "hidden",
        }}
        open={backdropOpen}
      >
        <CircularProgress sx={{ color: "#1361A1" }} />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        ContentProps={{
          sx: {
            background: snackbarBackground,
          },
        }}
        message={snackbarMessage}
        onClose={snackbarOnClose}
        open={open}
      />
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar
          setIsSidebar={setIsSidebar}
          sx={{ pl: 4, pr: 4, pt: 4, pb: 4 }}
        />
        <Box sx={{ ml: 3, mr: 3, mt: 4, mb: 4 }}>
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
            <Typography
              align="center"
              component="h2"
              gridColumn="span 12"
              gridRow="span 1"
              variant="h2"
            >
              {getTitle(action)}
            </Typography>
            <Box
              gridColumn="span 12"
              gridRow="span 1"
              sx={{
                width: "100%",
              }}
            >
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                sx={{ pl: 3, pr: 3, pt: 3, pb: 3 }}
              >
                <Formik
                  onSubmit={submit}
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={checkoutSchema(action)}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        {/* First Name */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={hiddenForChangePassword()}
                          xs={6}
                        >
                          <TextField
                            id="firstName"
                            name="firstName"
                            autoFocus
                            error={Boolean(
                              touched.firstName && errors.firstName
                            )}
                            fullWidth
                            InputProps={{
                              readOnly: readOnlyForViewAccountView(),
                            }}
                            label="First Name"
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                          />
                        </Grid>

                        {/* Last Name */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={hiddenForChangePassword()}
                          xs={6}
                        >
                          <TextField
                            id="lastName"
                            name="lastName"
                            error={Boolean(touched.lastName && errors.lastName)}
                            fullWidth
                            InputProps={{
                              readOnly: readOnlyForViewAccountView(),
                            }}
                            label="Last Name"
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                          />
                        </Grid>

                        {/* Username */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={hiddenForChangePassword()}
                          xs={6}
                        >
                          <TextField
                            id="userName"
                            name="userName"
                            error={Boolean(touched.userName && errors.userName)}
                            fullWidth
                            InputProps={{
                              readOnly: readOnlyForViewAccountView(),
                            }}
                            label="Username"
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.userName}
                          />
                        </Grid>

                        {/* Email Address */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={hiddenForChangePassword()}
                          xs={6}
                        >
                          <TextField
                            id="email"
                            name="email"
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            InputProps={{
                              readOnly: readOnlyForViewAccountView(),
                            }}
                            label="Email Address"
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                          />
                        </Grid>

                        {/* Project */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={showForCreate()}
                          xs={12}
                        >
                          <Autocomplete
                            id="projectAutocomplete"
                            autoHighlight
                            disableClearable={true}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, value) =>
                              (values.project = value)
                            }
                            options={projectList}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  touched.project && errors.project
                                )}
                                fullWidth
                                label="Projects"
                                onBlur={handleBlur}
                              />
                            )}
                            size="medium"
                            value={values.project || ""}
                          />
                        </Grid>

                        {/* Project For View */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={showForViewAccountView()}
                          xs={12}
                        >
                          <Autocomplete
                            id="projectForViewAutocomplete"
                            autoHighlight
                            disableClearable={true}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            multiple={true}
                            options={projectList}
                            readOnly={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Projects"
                                onBlur={handleBlur}
                              />
                            )}
                            size="medium"
                            value={values.projectForView || []}
                          />
                        </Grid>

                        {/* Role Type */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={hiddenForAccountUpdateChangePassword()}
                          xs={6}
                        >
                          <Autocomplete
                            id="roleTypeAutocomplete"
                            autoHighlight
                            disableClearable={true}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, value) =>
                              (values.roleType = value)
                            }
                            options={roleTypeList}
                            readOnly={readOnlyForViewAccountView()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  touched.roleType && errors.roleType
                                )}
                                fullWidth
                                label="Role Type"
                                onBlur={handleBlur}
                              />
                            )}
                            size="medium"
                            value={values.roleType || null}
                          />
                        </Grid>

                        {/* Account Status */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={hiddenForAccountUpdateChangePassword()}
                          xs={6}
                        >
                          <Autocomplete
                            id="accountStatusAutocomplete"
                            autoHighlight
                            disableClearable={true}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, value) =>
                              (values.accountStatus = value)
                            }
                            options={accountStatusList}
                            readOnly={readOnlyForViewAccountView()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  touched.accountStatus && errors.accountStatus
                                )}
                                fullWidth
                                label="Account Status"
                                onBlur={handleBlur}
                              />
                            )}
                            size="medium"
                            value={values.accountStatus || null}
                          />
                        </Grid>

                        {/* Old Password */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={showForChangePassword()}
                          xs={6}
                        >
                          <TextField
                            id="oldPassword"
                            name="oldPassword"
                            autoComplete="oldPassword"
                            autoFocus
                            error={
                              !!touched.oldPassword && !!errors.oldPassword
                            }
                            fullWidth
                            label="Old Password"
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.oldPassword}
                          />
                        </Grid>

                        <Grid container item xs={6} direction="column" />

                        {/* New Password */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={showForChangePassword()}
                          xs={6}
                        >
                          <TextField
                            id="newPassword"
                            name="newPassword"
                            autoComplete="newPassword"
                            autoFocus
                            error={
                              !!touched.newPassword && !!errors.newPassword
                            }
                            fullWidth
                            label="New Password"
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.newPassword}
                          />
                        </Grid>

                        {/* Confirm New Password */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={showForChangePassword()}
                          xs={6}
                        >
                          <TextField
                            id="newPasswordConfirm"
                            name="newPasswordConfirm"
                            autoComplete="newPasswordConfirm"
                            autoFocus
                            error={
                              !!touched.newPasswordConfirm &&
                              !!errors.newPasswordConfirm
                            }
                            fullWidth
                            label="Confirm Password"
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.newPasswordConfirm}
                          />
                        </Grid>

                        <Grid container direction="column" item xs={12} />

                        {/* Cancel Button */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={hiddenForAccountView()}
                          xs={6}
                        >
                          <Button
                            type="button"
                            fullWidth
                            onClick={cancel}
                            sx={{ mt: 2, mb: 2 }}
                            style={{ backgroundColor: "#919191" }}
                            variant="contained"
                          >
                            {cancelButtonLabel(action)}
                          </Button>
                        </Grid>

                        {/* Change Password Button */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={showForAccountView()}
                          xs={6}
                        >
                          <Link to="/account/change-password">
                            <Button
                              type="button"
                              fullWidth
                              sx={{ mt: 2, mb: 2 }}
                              style={{ backgroundColor: "#1361A1" }}
                              variant="contained"
                            >
                              Change Password
                            </Button>
                          </Link>
                        </Grid>

                        {/* Update Button */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={showForViewAccountView}
                          xs={6}
                        >
                          <Link to={getUpdatePath()}>
                            <Button
                              type="button"
                              fullWidth
                              sx={{ mt: 2, mb: 2 }}
                              style={{ backgroundColor: "#1361A1" }}
                              variant="contained"
                            >
                              Update
                            </Button>
                          </Link>
                        </Grid>

                        {/* Submit Button */}
                        <Grid
                          container
                          direction="column"
                          item
                          sx={hiddenForView}
                          xs={6}
                        >
                          <Button
                            type="submit"
                            fullWidth
                            sx={{ mt: 2, mb: 2 }}
                            style={{ backgroundColor: "#1361A1" }}
                            variant="contained"
                          >
                            {submitButtonLabel(action)}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Box>
      </main>
    </div>
  );
};

const getTitle = (action) => {
  if (ACTION_CREATE === action) {
    return "Create Account";
  } else if ([ACTION_UPDATE, ACTION_ACCOUNT_UPDATE].includes(action)) {
    return "Update Account";
  } else if ([ACTION_VIEW, ACTION_ACCOUNT_VIEW].includes(action)) {
    return "Account";
  } else if (ACTION_ACCOUNT_CHANGE_PASSWORD === action) {
    return "Change Password";
  }
};

const getInitialValues = (action, account) => {
  if (ACTION_CREATE === action) {
    return {
      id: -1,
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      project: !!account?.projectList ? account.projectList[0] : "",
      projectForView: [],
      roleType: { id: "User", name: "User" },
      accountStatus: { id: "Unverified", name: "Unverified" },
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    };
  } else if ([ACTION_UPDATE, ACTION_ACCOUNT_UPDATE].includes(action)) {
    return {
      id: !!account?.id ? account.id : -1,
      firstName: !!account?.firstName ? account.firstName : "",
      lastName: !!account?.lastName ? account.lastName : "",
      userName: !!account?.userName ? account.userName : "",
      email: !!account?.email ? account.email : "",
      project: "",
      projectForView: [],
      roleType: !!account?.roleList
        ? { id: account.roleList[0], name: account.roleList[0] }
        : undefined,
      accountStatus: !!account?.accountStatus
        ? { id: account.accountStatus, name: account.accountStatus }
        : undefined,
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    };
  } else if ([ACTION_VIEW, ACTION_ACCOUNT_VIEW].includes(action)) {
    return {
      id: !!account?.id ? account.id : -1,
      firstName: !!account?.firstName ? account.firstName : "",
      lastName: !!account?.lastName ? account.lastName : "",
      userName: !!account?.userName ? account.userName : "",
      email: !!account?.email ? account.email : "",
      project: "",
      projectForView: !!account?.projectList ? account.projectList : [],
      roleType: !!account?.roleList
        ? { id: account.roleList[0], name: account.roleList[0] }
        : undefined,
      accountStatus: !!account?.accountStatus
        ? { id: account.accountStatus, name: account.accountStatus }
        : undefined,
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    };
  } else {
    return {
      id: -1,
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      project: "",
      projectForView: [],
      roleType: undefined,
      accountStatus: undefined,
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    };
  }
};

const checkoutSchema = (action) => {
  if (ACTION_CREATE === action) {
    return yup.object().shape({
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      userName: yup.string().required("required"),
      email: yup.string().email("Invalid email").required("required"),
      project: yup.object().required("required"),
      roleType: yup.object().required("required"),
      accountStatus: yup.object().required("required"),
    });
  } else if (ACTION_UPDATE === action) {
    return yup.object().shape({
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      userName: yup.string().required("required"),
      email: yup.string().email("Invalid email").required("required"),
      roleType: yup.object().required("required"),
      accountStatus: yup.object().required("required"),
    });
  } else if (ACTION_ACCOUNT_UPDATE === action) {
    return yup.object().shape({
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      userName: yup.string().required("required"),
      email: yup.string().email("Invalid email").required("required"),
    });
  } else if (ACTION_ACCOUNT_CHANGE_PASSWORD === action) {
    return yup.object().shape({
      oldPassword: yup.string().required("required"),
      newPassword: yup.string().required("required"),
      newPasswordConfirm: yup
        .string()
        .required("required")
        .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
    });
  }

  return yup.object().shape({});
};

export default AccountForm;
