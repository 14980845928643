import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import VisualGrid from "./VisualGrid";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function VisualTabPanel({projectName, nodeId, gridData, tabId, setTabId, setGridData, setVisualImageId, setCollectionTime}) {
    const handleChange = (event, newValue) => {
        setTabId(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabId} onChange={handleChange} aria-label="visual tabs">
                    <Tab label="Inspection List" {...a11yProps(0)} />
                </Tabs>
            </Box>
            <TabPanel value={tabId} index={0}>
                <VisualGrid
                    gridData={gridData}
                    nodeId={nodeId}
                    projectName={projectName}
                    setGridData={setGridData}
                    setVisualImageId={setVisualImageId}
                    setCollectionTime={setCollectionTime}
                />
            </TabPanel>
        </Box>
    );
}
