import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import Moment from 'moment';
import {useEffect, useState} from "react";
import {measurementActions} from "../../store";
import {useDispatch} from "react-redux";
import {Button} from "@mui/material";

export default function VibrationGrid({projectName, nodeId, setCollectionTime, setGraphType}) {
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState("collectionTime,desc");
    const [page, setPage] = useState(0);
    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleClickPlotButton = (event, row) => {
        event.stopPropagation();
        setCollectionTime(row.collectionTime);
        setGraphType("plot");
    };
    const handleClickCompareButton = (event, row) => {
        event.stopPropagation();
        setCollectionTime(row.collectionTime);
        setGraphType("compare");
    };

    const handleSortModelChange = (sortModel) => {
        if (sortModel && sortModel.length > 0) {
            const orderBy = sortModel[0].field;
            const order = sortModel[0].sort;
            setSort(orderBy + "," + order);
        }
    };

    function showMap(latitude, longitude) {
        let url = "https://maps.google.com/?q=" + latitude + "," + longitude;
        window.open(url);
    }

    const columns = [
        {field: 'id', headerName: 'ID', width: 90, hide: true},
        {
            field: 'collectionTime',
            headerName: 'Timestamp',
            width: 160,
            editable: false,
            align:'center',
            headerAlign: "center",
            sortable: false,
            valueFormatter: params => new Moment(params?.value).format("YYYY/MM/DD HH:mm:ss"),
        },
        {
            field: 'overall',
            headerName: 'Overall G',
            type: 'number',
            align:'center',
            headerAlign: "center",
            width: 130,
            editable: false,
            sortable: false,
        },
        {
            field: 'peak',
            headerName: 'Peak G',
            type: 'number',
            align:'center',
            headerAlign: "center",
            width: 130,
            editable: false,
            sortable: false,
        },
        { field: 'plot', headerName: '',sortable: false, width: 100, renderCell: (params) => {
                return (
                    <Button
                        onClick={(e) => handleClickPlotButton(e, params.row)}
                        variant="contained"
                        // color="primary"
                        style={{
                            backgroundColor: "#4169E1",
                            fontSize: "12px",
                            margin: "4px",
                            alignItems: "center"
                        }}
                    >
                        Plot
                    </Button>
                );
            }
        },
        {
            field: 'compare', headerName: '', sortable: false, width: 100, renderCell: (params) => {
                return (
                    <Button
                        onClick={(e) => handleClickCompareButton(e, params.row)}
                        variant="contained"
                        // color="primary"
                        style={{
                            backgroundColor: "#4169E1",
                            fontSize: "12px",
                            margin: "4px",
                            alignItems: "center"
                        }}
                    >
                        Compare
                    </Button>
                );
            }
        },
        {
            field: 'city',
            headerName: 'Location',
            align:'center',
            headerAlign: "center",
            width: 190,
            editable: false,
            sortable: false,
        },
        {
            field: 'maps', headerName: '', width: 90, sortable: false, renderCell: (params) => {
                return (
                    <Button
                        onClick={() => showMap(params.row.latitude, params.row.longitude)}
                        variant="contained"
                        style={{
                            backgroundColor: "#4169E1",
                            fontSize: "12px",
                            margin: "4px",
                            alignItems: "center"
                        }}
                    >
                        Map
                    </Button>
                );
            }
        },
        {
            field: 'technician',
            headerName: 'Technician',
            align:'center',
            headerAlign: "center",
            width: 160,
            editable: false,
        },
        {
            field: 'condition',
            headerName: 'Condition',
            align:'center',
            headerAlign: "center",
            width: 300,
            editable: false,
            sortable: false,
        }
    ];

    useEffect(() => {
        let pageable = { size: pageSize, page, sort };
        if (nodeId) {
            if (nodeId.substring(0,2) === "C-") {
                setIsLoading(true);
                projectName && dispatch(measurementActions.getMeasurementList({
                    projectName: projectName,
                    componentId: nodeId.substring(2),
                    noTriAxial: true,
                    pageable: pageable
                }))
                    .then((state) => {
                        if (state && state.payload) {
                            setGridData(state.payload.content);
                            setRowCount(state.payload.totalElements);
                        } else {
                            setGridData([]);
                            setRowCount(0);
                        }
                        setIsLoading(false);
                    });
            } else {
                setGridData([]);
                setIsLoading(false);
            }
        }
    }, [dispatch, nodeId, projectName, sort, page, pageSize]);

    if (!gridData || gridData.length === 0) {
        return;
    }
    return (
        <div>
            <Box height="69vh" m="-10px 0 0 0">
                <DataGrid
                    columns={columns}
                    loading={isLoading}
                    onSortModelChange={handleSortModelChange}
                    sortingMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    pagination
                    paginationMode="server"
                    pageSize={pageSize}
                    rowCount={rowCount}
                    rows={gridData}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    getCellClassName={(params) => {
                        if (params.field === 'overall') {
                            if (params.value >= 1.25) {
                                return 'danger';
                            }
                            else if (params.value >= 0.707) {
                                return 'warning';
                            }
                            else {
                                return 'normal';
                            }
                        }
                        if (params.field === 'peak') {
                            if (params.value >= 4.0) {
                                return 'danger';
                            }
                            else if (params.value >= 2.0) {
                                return 'warning';
                            }
                            else {
                                return 'normal';
                            }
                        }
                    }}

                    sx={{
                        '& .normal': {
                        },
                        '& .warning': {
                            backgroundColor: '#cccc0022',
                        },
                        '& .danger': {
                            backgroundColor: '#ff000022',
                        },
                    }}

                    components={{ Toolbar: GridToolbar }}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
        </div>
    );
}
