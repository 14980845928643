import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {measurementActions} from "../../store";
import {Box} from "@mui/material";
import {Chart} from "react-google-charts";
import * as React from "react";

export default function GenTWFPlot({nodeId, projectName, setGraphType, collectionTime, setCollectionTime}) {
    const dispatch = useDispatch();
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                projectName && dispatch(measurementActions.getTWF({
                    projectName: projectName,
                    componentId: nodeId.substring(2),
                    collectionTime
                }))
                    .then((state) => {
                        setGraphData(state.payload);
                    });
            } else {
                setGraphData([]);
            }
        }
    }, [dispatch, nodeId, projectName]);

    if (!graphData || graphData.length === 0) {
        return;
    }
    const options = {
        chartArea: {width: "100%", left: "135", right: "30"},
        curveType: "",
        crosshair: { trigger: 'both' , color: 'red'},
        hAxis: {
            title: "time (s)",
            textStyle: {
                fontSize: 12
            },
            slantedText: true,
            slantedTextAngle: 45,
        },
        vAxis: {
            title: "G's",
        },
        legend: {position: "top"},
        explorer: {
            actions: ['dragToZoom', 'rightClickToReset'],
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 50.0
        },
    };

    const handleClickButton = (event) => {
        event.stopPropagation();
        setGraphType(null);
        setCollectionTime(null)
    };

    const handleFFTButton = () => {
        setGraphType("fft");
    };
    const handleFFTvelButton = () => {
        setGraphType("fftvel");
    };


    function downloadCsv() {
        let data = [];
        for (let i= 0; i < graphData.length; i++) {
            data += graphData[i] + "\n"
        }
        const blob = new Blob([data], { type: 'text/csv'});
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
    }

    return (
        <div>
            {/*<Box display="flex" height="7vh">*/}
            {/*    <PlotTabs*/}
            {/*    />*/}
            {/*</Box>*/}
            <button onClick={handleClickButton}>Close Plot</button>
            {" "}
            <button onClick={downloadCsv}>Download CSV</button>
            {" "}
            <button onClick={handleFFTButton}>Acc Spectrum</button>
            {" "}
            <button onClick={handleFFTvelButton}>Vel Spectrum</button>

            <Box display="flex" height="73vh" p="14px">
                <Chart
                    chartType="LineChart"
                    data={graphData}
                    options={options}
                    height="98%"
                    style={{flex: 2}}
                />
            </Box>
        </div>
    );
}
