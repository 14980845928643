import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {measurementActions} from "../../store";
import {Box} from "@mui/material";
import {Chart} from "react-google-charts";
import * as React from "react";

export default function FFTPlot({nodeId, projectName, setGraphType, collectionTime, setCollectionTime}) {
    const dispatch = useDispatch();
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                projectName && dispatch(measurementActions.getFFT({
                    projectName: projectName,
                    componentId: nodeId.substring(2),
                    collectionTime,
                    type:"Acceleration"
                }))
                    .then((state) => {
                        setGraphData(state.payload);
                    });
            } else {
                setGraphData([]);
            }
        }
    }, [dispatch, nodeId, projectName]);

    const options = {
        chartArea: {width: "100%", left: "135", right: "30"},
        curveType: "",
        crosshair: { trigger: 'both' , color: 'red'},
        hAxis: {
            title: "frequency (Hz)",
            textStyle: {
                fontSize: 12
            },
            slantedText: true,
            slantedTextAngle: 45,
        },
        vAxis: {
            title: "G's",
        },
        style: {
            cursor: 'crosshair',
        },
        legend: {position: "top"},
        explorer: {
            actions: ['dragToZoom', 'rightClickToReset'],
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 50.0
        },
    };

    const handleClickButton = (event) => {
        event.stopPropagation();
        setGraphType(null);
        setCollectionTime(null)
    };

    const handleTWFButton = () => {
        setGraphType("plot");
    };

    if (!graphData || graphData.length === 0) {
        return;
    }

    return (
        <div>
            {/*<Box display="flex" height="7vh">*/}
            {/*    <PlotTabs*/}
            {/*    />*/}
            {/*</Box>*/}
            <button onClick={handleClickButton}>Close Plot</button>
            {" "}
            <button onClick={handleTWFButton}>TWF Plot</button>
            <Box display="flex" height="73vh" p="14px">
                <Chart
                    chartType="LineChart"
                    data={graphData}
                    options={options}
                    height="98%"
                    style={{flex: 2}}
                />
            </Box>
        </div>
    );
}
