import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {fetchWrapper} from '../../helpers';
import {API_GET_AUTHORIZATION_TOKEN} from "../../constants/network";

// create slice
const name = 'authorization';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const authSlice = createSlice({name, initialState, reducers, extraReducers});

// exports
export const authActions = {...authSlice.actions, ...extraActions};

// implementation
function createInitialState() {
    let isLoggedIn = false;
    let accessToken = null;
    let authorizationData = JSON.parse(localStorage.getItem('authorization'));
    if (authorizationData) {
        if (authorizationData.accessToken && authorizationData.exp) {
            let expirationTime = new Date(authorizationData.exp);
            if (expirationTime < new Date()) {
                console.log("Authorization has expired", expirationTime, new Date());
                authorizationData = null;
                localStorage.removeItem('authorization');
            } else {
                isLoggedIn = true;
                accessToken = authorizationData.accessToken;
            }
        } else {
            authorizationData = null;
            localStorage.removeItem('authorization');
        }
    }
    return {
        accessToken: accessToken,
        isLoggedIn: isLoggedIn,
        authorization_data: authorizationData,
        error: null
    }
}

function createReducers() {
    return {
        logout
    };

    function logout(state) {
        state.user = null;
        localStorage.removeItem('authorization');
    }
}

function createExtraActions() {
    return {
        login: login()
    };

    function login() {
        return createAsyncThunk(
            '/authorization/token',
            async ({email, password}) => await fetchWrapper.post(API_GET_AUTHORIZATION_TOKEN, {email, password})
        );
    }
}

function createExtraReducers() {
    return {
        ...login()
    };

    function login() {
        let {pending, fulfilled, rejected} = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('authorization', JSON.stringify(action.payload));
                state.authorization = action.payload;
                return state;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}

export default authSlice;