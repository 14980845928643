import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import GenTrendPlot from "./GenTrendPlot";
import GenTrendVelPlot from "./GenTrendVelPlot";
import GenVibrationGrid from "./GenVibrationGrid";
import GenVibrationImage from "./GenVibrationImage"

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function GenVibrationTabPanel({isDashboard, nodeId, projectName, tabId, setTabId, setGraphType,
                                                 setCollectionTime}) {
    const handleChange = (event, newValue) => {
        setTabId(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabId} onChange={handleChange} aria-label="gen vibration tabs">
                    <Tab label="Readings" {...a11yProps(0)} />
                    <Tab label="Acc Trend" {...a11yProps(1)} />
                    <Tab label="Vel Trend" {...a11yProps(2)} />
                    <Tab label="Instructions/Image" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={tabId} index={0}>
                <GenVibrationGrid
                    isDashboard={isDashboard}
                    nodeId={nodeId}
                    projectName={projectName}
                    setCollectionTime={setCollectionTime}
                    setGraphType={setGraphType}
                    isCac={false}
                />
            </TabPanel>

            <TabPanel value={tabId} index={1}>
                <GenTrendPlot
                    nodeId={nodeId}
                    projectName={projectName}/>
            </TabPanel>

            <TabPanel value={tabId} index={2}>
                <GenTrendVelPlot
                    nodeId={nodeId}
                    projectName={projectName}/>
            </TabPanel>

            <TabPanel value={tabId} index={3}>
                <GenVibrationImage
                    nodeId={nodeId}
                    projectName={projectName}/>
            </TabPanel>
        </Box>
    );
}
