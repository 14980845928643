import {Box, Typography} from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ProjectSelect({projectList, projectName, setProjectName}) {
    const projectNode = !projectName ? projectList[0] : projectList.find((element) => {
        return element.name === projectName;
    })
    return (
        <Autocomplete
            id="project-select-autocomplete"
            sx={{ width: '75hw', fontSize: '24px' }}
            size="medium"
            options={projectList}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            autoHighlight
            getOptionLabel={(option) => option.name}
            disableClearable={true}
            defaultValue={projectNode}
            onChange={(event, option) => {
                setProjectName(option.name);
            }}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Typography variant="h5" fontWeight="600">
                        {option.name}
                    </Typography>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Choose a project"
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
}