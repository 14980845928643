import { API_ACCOUNT } from "../../constants/network";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../helpers";

// Create Slice:
const name = "account";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const accountSlice = createSlice({ name, initialState, extraReducers });

// Exports:
export const accountActions = { ...accountSlice.actions, ...extraActions };

// Implementation:
function createInitialState() {
  return {
    account: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      roleList: [],
      projectList: [],
      error: null,
      loading: false,
    },
  };
}

function createExtraActions() {
  function accountGet() {
    return createAsyncThunk(
      "/accounts",
      async () => await fetchWrapper.get(API_ACCOUNT, null)
    );
  }

  function accountUpdate() {
    return createAsyncThunk(
      "accounts/update",
      async ({ body }) => await fetchWrapper.put(API_ACCOUNT, body)
    );
  }

  function accountChangePassword() {
    return createAsyncThunk(
      "accounts/change-password",
      async ({ body }) =>
        await fetchWrapper.put(`${API_ACCOUNT}/password`, body)
    );
  }

  return {
    accountGet: accountGet(),
    accountUpdate: accountUpdate(),
    accountChangePassword: accountChangePassword(),
  };
}

function createExtraReducers() {
  function accountGet() {
    let { pending, fulfilled, rejected } = extraActions.accountGet;

    return {
      [pending]: (state) => {
        state.account = {
          firstName: "",
          lastName: "",
          userName: "",
          email: "",
          roleList: [],
          projectList: [],
          error: null,
          loading: false,
        };
      },
      [fulfilled]: (state, action) => {
        state.account = action.payload;

        return state;
      },
      [rejected]: (state, action) => {
        state.account = { error: action.error };
        state.account.loading = false;
      },
    };
  }

  function accountUpdate() {
    let { pending, fulfilled, rejected } = extraActions.accountUpdate;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function accountChangePassword() {
    let { pending, fulfilled, rejected } = extraActions.accountChangePassword;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state) => {
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  return {
    ...accountGet(),
    ...accountUpdate(),
    ...accountChangePassword(),
  };
}

export default accountSlice;
