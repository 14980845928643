import Avatar from "@mui/material/Avatar";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Formik } from "formik";
import { publicActions } from "../../store";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useState } from "react";

import * as React from "react";
import * as yup from "yup";
import Copyright from "../../components/Copyright";

const initialValues = {
  email: "",
  password: "",
  passwordConfirm: "",
};

const checkoutSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("required"),
  password: yup.string().required("required"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const RecoverPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Backdrop {{
  const [backdropOpen, setBackdropOpen] = useState(false);
  // }} Backdrop

  // Snackbar {{
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarBackground, setSnackbarBackground] = useState("green");
  const [snackbarRedirect, setSnackbarRedirect] = useState(false);
  const [snackbarRedirectPath] = useState("/");
  const snackbarOnClose = () => {
    setOpen(false);

    if (snackbarRedirect) {
      navigate(snackbarRedirectPath);
    }
  };
  // }} Snackbar

  const { resetKey } = useParams();

  function onSubmit({ email, password }) {
    setBackdropOpen(true);

    dispatch(publicActions.passwordResetInit({ resetKey })).then((state) => {
      if (state) {
        if (state.type === "/public/passwordResetInit/fulfilled") {
          let resetCode = state?.payload?.resetCode;

          dispatch(
            publicActions.passwordResetFinish({
              email,
              password,
              resetKey,
              resetCode,
            })
          ).then((state) => {
            setBackdropOpen(false);

            if (state) {
              if (state.type === "/public/passwordResetFinish/fulfilled") {
                setSnackbarMessage("Password changed");
                setSnackbarBackground("green");
                setSnackbarRedirect(true);
              } else if (
                state.type === "/public/passwordResetFinish/rejected"
              ) {
                setSnackbarMessage("Reset code invalid or expired");
                setSnackbarBackground("red");
                setSnackbarRedirect(false);
              }

              setOpen(true);
            }
          });
        } else if (state.type === "/public/passwordResetInit/rejected") {
          setBackdropOpen(false);
          setSnackbarMessage("Reset key invalid or expired");
          setSnackbarBackground("red");
          setSnackbarRedirect(false);
          setOpen(true);
        }
      } else {
        setBackdropOpen(false);
      }
    });
  }

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflow: "hidden",
        }}
        open={backdropOpen}
      >
        <CircularProgress sx={{ color: "#1361A1" }} />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        ContentProps={{
          sx: {
            background: snackbarBackground,
          },
        }}
        message={snackbarMessage}
        onClose={snackbarOnClose}
        open={open}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginTop: 8,
          }}
        >
          <Avatar
            sx={{ m: 1, bgcolor: "secondary.main", height: 80, width: 80 }}
          >
            <img
              alt="reliable_plane"
              height="80px"
              src={"../../icon192.png"}
              width="80px"
            />
          </Avatar>
          <Typography
            component="h1"
            variant="h3"
            sx={{ marginTop: 2, marginBottom: 3 }}
          >
            Recover Password
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={checkoutSchema}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mt: 1 }}>
                  <TextField
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={!!touched.email && !!errors.email}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                  />
                  <TextField
                    id="password"
                    name="password"
                    autoComplete="password"
                    autoFocus
                    error={!!touched.password && !!errors.password}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    type="password"
                    value={values.password}
                  />
                  <TextField
                    id="passwordConfirm"
                    name="passwordConfirm"
                    autoComplete="passwordConfirm"
                    autoFocus
                    error={
                      !!touched.passwordConfirm && !!errors.passwordConfirm
                    }
                    fullWidth
                    helperText={
                      touched.passwordConfirm && errors.passwordConfirm
                    }
                    label="Confirm Password"
                    margin="normal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    type="password"
                    value={values.passwordConfirm}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    sx={{ mt: 5, mb: 3 }}
                    variant="contained"
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </div>
  );
};

export default RecoverPassword;
